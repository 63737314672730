<template>
    <div class="container">
        <form>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">名 称<i class="rqd">*</i></label>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业名称" required v-model.trim.lazy="data.name" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >企 业<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.company_id">
                    <option v-for="companyList in companyData" :key="companyList.id" :value="companyList.id"> {{ companyList.name }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >类 型<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.type_id">
                    <option v-for="(jobsType,index) in JOBS_TYPE" :key="index" :value="index"> {{ jobsType }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >年龄要求<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.schooling">
                    <option v-for="(value,index) in AGEINDEX" :key="index" :value="index"> {{ value }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >学历要求<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.schooling">
                    <option v-for="(jobsSchooling,index) in JOBS_SCHOOLING" :key="index" :value="index"> {{ jobsSchooling }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >综合工资<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.salarys">
                    <option v-for="(salarys,index) in SALARYINDEX" :key="index" :value="index"> {{ salarys }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >福利待遇<i class="rqd"></i></span>
                <div class="w-100">
                    <select class="form-select p-2 w-100 rounded" multiple="multiple" v-model="data.tags">
                        <option v-for="(name, code) in ADVANTAGE" :key="code" :value="code">{{ name }}</option>
                    </select>
                </div>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >首页显示<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.homeshow">
                    <option value="0">小时工</option>
                    <option value="1">返 费</option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">首页显示单价</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入单价，不做计薪" v-model.trim.lazy="data.home_price " />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">招聘人数</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入招聘人数" v-model.trim.lazy="data.people_number " />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">面试时间</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入面试时间" v-model.trim.lazy="data.interview_time " />
            </div>
            <div id="address" class="input-group my-1 has-feedback">
                <label class="m-1">地 址</label>
                <myAddress @changed="saveAddr"/>
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">联系人</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系人姓名" v-model.trim.lazy="data.contact" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">联系电话</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系电话" v-model.trim.lazy="data.contact_tel" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">其它说明</label>
                <textarea class="form-control w-100 p-2" rows="6" v-model.trim.lazy="data.content"></textarea>
            </div>
            <button class="btn btn-success btn-lg w-100 mt-5" @click.prevent="add">提交</button>
        </form>
    </div>
    <Hint v-if="isOk" :msg="msg" @btnok="isOk=false"></Hint>
</template>

<script>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { JOBS_TYPE, JOBS_SCHOOLING, SALARYINDEX, ADVANTAGE, AGEINDEX } from '@/lib/data'
import myAddress from '@/components/lib/address'
export default {
    name:'jobsAdd',
    components: {
        myAddress
    },
    setup() {
        const msg = { type: 'success', message: '提交成功', url: ''}
        const isOk = ref(false)
        const companyData = ref([
            {"id":100,"name":"一二一通信"},
            {"id":101,"name":"97打工网"}
        ])
        let data = ref({
                company_id: 100,//企业 Number
                name: "", //名称 Strying
                type_id:0,//岗位类型 Number < 100
                content: "", //其它说明 Strying
                salarys: 1,//综合工资 Number < 30
                tags: [],//包吃包住、不穿无尘衣 Array
                schooling: 0, //学历要求 Number
                ages: 1, //年龄要求 Number
                address: [], //地址 Array
                interview_time: "",//面试时间 Strying
                people_number: 1000,//招聘人数 Number
                homeshow: 0, //首页显示 Number
                home_price: 20, //首页显示单价 Number
                contact: "", //联系人 Strying
                contact_tel: 180180180888,
        })

        function saveAddr(val) {
            data.value.address = val
            console.log(data.value.address)
        }
        
        function getcompany(){
            Axios.get('http://localhost:5000/companys').then((response) => {
                if(response.data.code == 0){
                    // companyData.value =response.data.data
                }
            })
        }
        onMounted(() => {
            getcompany()
        })
        //
        function add(){
            Axios.post('http://localhost:5000/jobs',data.value).then((response) => {
                if (response.data.code == 200){
                    msg.type = 'success'
                    msg.message = '添加成功'
                    msg.url = '/jobs/'
                    isOk.value = true
                    return
                } else {
                    msg.type = 'danger'
                    msg.message = response.data.message
                    isOk.value = true
                    return
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        return {
            msg,
            add,
            isOk,
            JOBS_SCHOOLING,
            data,
            SALARYINDEX,
            companyData,
            JOBS_TYPE,
            ADVANTAGE,
            AGEINDEX,
            saveAddr
        }
    }
}
</script>

<style scoped>
.rqd {
    color: red;
}
</style>